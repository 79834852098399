import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import CampaignCard from "../../components/CampaignCard/CampaignCard";
import './List.scss'
const List = ({merchants, campaigns, focusCampaign}) => {
  return (
    <div className='campaignsList'>
      { campaigns.length && merchants.length ?
            <div className='campaignsList-item'>
              {campaigns?.map((campaign, index) =>
                index < 8 ? (
                  <CampaignCard
                    focus={focusCampaign.campaign_id == campaign.campaign_id }
                    campaign={campaign}
                    merchant={merchants?.find(i => i.merchant_id == campaign.merchant_id)}
                  />) : ''
              )}
        </div>
        : <div>Loading...</div>}
    </div>
  )
}

export default List