import React from 'react'
import { Link } from 'react-router-dom'
import { Carousel } from '../../components'
import './CarouselCard.scss'
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import cashbackImg from '../../assets/imgs/cashback.svg'
const CarouselCard = ({merchants, campaigns}) => {
  return (
    <div className='carouselCard'>
      <div className="carouselCard-header">
        <div className='carouselCard-header__text'>
          <h2>Reembolsos nas suas compras</h2>
          <p>Pague com os seus cartões Santander e comece já a poupar</p>
        </div>
        <div className='carouselCard-header__link'>
          <Link to='/campaigns'>
            Ver todas as oportunidades
          </Link>
        </div>

      </div>
      <Box sx={{ pb: 4,}}>
        <Carousel campaigns={campaigns} merchants={merchants} />
      </Box>
      <Divider />
      <Box pt={4}>
        <div className='carouselCard-footer'>
          <div  className='carouselCard-footer__info'>
            <img  src={cashbackImg} alt="cashback"/>
            <div>
              <h5>Já acumulou 23,30€ em reembolso com as suas compras</h5>
              <p>Para receber o dinheiro na sua conta deve aceiter os <a href="#">Termos e Condições</a></p>
            </div>
          </div>
          <div className='carouselCard-footer__action'>
            <button>Aceitar e receber dinheiro</button>
          </div>
        </div>
      </Box>
    </div>
  )
}

export default CarouselCard