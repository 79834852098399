import React, { Component } from "react";
import Slider from "react-slick";
import CarouselItem from "../CarouselItem/CarouselItem";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Carousel.scss'
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { LocalDining } from "@mui/icons-material";



const Carousel = ({merchants, campaigns}) => {

 const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 2,
    className: 'slickSliderCarousel',
    initialSlide: 0,
    nextArrow: <MdArrowForwardIos />,
    prevArrow: <MdArrowBackIos />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return(
    campaigns.length && merchants.length ?
    <div class="carousel">
      <Slider {...settings}>
        {campaigns?.map((campaign, index) => (
          <CarouselItem
            campaign={campaign}
            merchant={merchants?.find(i => i.merchant_id == campaign.merchant_id)}
            key={index}
          />
        ))}
      </Slider>
    </div> : <div>Loading...</div>
  )
}

export default Carousel