import React from 'react'
import './Popup.scss'
import { IoMdInformationCircle } from "react-icons/io";
import { IconContext } from "react-icons";
import { getSiteFromUrl, relativeDate } from '../../utils';
import urgentIcon from '../../assets/imgs/urgent.svg';
import { formateDate } from '../../utils';
const Popup = ({campaign}) => {
  const {day, dayDisplay} = relativeDate(campaign.end_date);
  return (
    <div className='popup'>
      <div className='popup-header' style={{backgroundImage: `url(${campaign.popup_image || campaign.header_image})`}} />
      <div className='popup-content'>
          {day < 3 ?
          <div className='popup-content__warning'>
            <img src={urgentIcon} alt="urgent"/><p>{`${dayDisplay}`}</p>
          </div> :
          <div className='popup-content__warning green'>
            <p>{`${formateDate(campaign.end_date)}`}</p>
          </div>
          }
        <div className='popup-content__logo'>
          <img src={campaign.logo} alt={campaign.merchant_name}/>
        </div>
        <div className='popup-content__details'>
          <div className='popup-content__details__intro'>
            <h6>{campaign.merchant_name}</h6>
            <h5 className='strong'>{campaign.details}</h5>
            <a href={campaign.url} target='_blank' rel="noreferrer">{ campaign.url_text || getSiteFromUrl(campaign.url)}</a>
          </div>
          <div className={`popup-content__details__extra ${!campaign.extra_offer_info && 'hidden-box'}`}>
          {campaign.extra_offer_info &&
            <>
              <IconContext.Provider value={{ color: "blue", className: "infoIcon", size: 18}}>
                <IoMdInformationCircle />
              </IconContext.Provider>
              <p>{campaign.extra_offer_info}</p>
            </>
          }
          </div>
          <div className='popup-content__details__conditions'>
            <h5>Condições para ser elegível</h5>
            <p>{campaign.conditions}</p>
          </div>
          {/* <h3 className='bannerInfo'>O reembolso será feito na sua conta num prazo de 3 dias úteis</h3> */}
        </div>
      </div>
    </div>
  )
}

export default Popup