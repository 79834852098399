import React, {useState, useEffect} from 'react'
import { Header, List } from '../../containers'
import MERCHANTS from "../../mock/mock-merchants";
import CAMPAIGNS from "../../mock/mock-campaigns";
import Sidebar from "../../containers/Sidebar/Sidebar";
import api from "../../api";

const Campaigns = () => {
  const [merchants, setMerchants] = useState([]);
  const [campaigns, setCampaigns] = useState([]);

  const [focusCampaign, setFocusCampaign] = useState({});
  const onSearchCampaign = (id) => {
    const campaign = campaigns.find(campaign => campaign.campaign_id == id);
    setFocusCampaign(campaign);
    const newSetOfCampaigns = [
      campaign,
      ...campaigns.filter(campaign => campaign.campaign_id != id),
    ]
    setCampaigns(newSetOfCampaigns);
  }
  useEffect(() => {
    api.get("/campaigns/").then((res) => {
      if(res.data){
        setCampaigns(res.data.results);
      }
    });
    api.get("/merchants/").then((res) => {
      if(res.data){
        setMerchants(res.data.results);
      }
    });
  }, []);
  return (
    <>
      <Sidebar />
      <main>
        <Header searchCampaign={(id) => onSearchCampaign(id)}/>
        <List
          focusCampaign={focusCampaign}
          campaigns={campaigns}
          merchants={merchants}
        />
      </main>
    </>
  )
}

export default Campaigns