import React from 'react'
import Search from "../../components/Search/Search";
import './Header.scss'
const Header = ({searchCampaign}) => {
  return (
    <header>
      <Search searchCampaign={(id) => searchCampaign(id)}/>
    </header>
  )
}

export default Header