import axios from "axios";

const api = axios.create({
    baseURL: "https://api-dev.datafordeals.com/api/",
    method: 'GET',
    mode: 'no-cors',
      headers: {
        'Authorization': 'Token 96338a8ea1a3f8b3c2b2b13d6d0522ef6f6cfcac',
        'Content-Type': 'application/json',
      },
})


export default api
