import React, { useState, useEffect } from "react";
import { CarouselCard, Header } from "../../containers";
import MERCHANTS from "../../mock/mock-merchants";
import CAMPAIGNS from "../../mock/mock-campaigns";
import api from "../../api";
const Home = () => {
  const [merchants, setMerchants] = useState([]);
  const [campaigns, setCampaigns] = useState([]);

  const [focusCampaign, setFocusCampaign] = useState({});
  const onSearchCampaign = (id) => {
    const campaign = campaigns.find((campaign) => campaign.campaign_id == id);
    setFocusCampaign(campaign);
    const newSetOfCampaigns = [
      campaign,
      ...campaigns.filter((campaign) => campaign.campaign_id != id),
    ];
    setCampaigns(newSetOfCampaigns);
  };

  const getCampaigns = async () => {
    const response = await fetch('http://api-dev.datafordeals.com/api/campaigns/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token 96338a8ea1a3f8b3c2b2b13d6d0522ef6f6cfcac',
      },
      credentials: 'include',
    });
    console.log(response.json())
  }
  useEffect(() => {
    api.get("/campaigns/").then((res) => {
      if(res.data){
        setCampaigns(res.data.results);
      }
    });
    api.get("/merchants/").then((res) => {
      if(res.data){
        setMerchants(res.data.results);
      }
    });
    getCampaigns()
  }, []);
  return (
    <>
      <Header searchCampaign={(id) => onSearchCampaign(id)} />
      <CarouselCard campaigns={campaigns} merchants={merchants} />
    </>
  );
};

export default Home;
