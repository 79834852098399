import React from 'react'
import { Link } from "react-router-dom";
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { BiMenuAltRight } from "react-icons/bi";
import HomeIcon from '@mui/icons-material/Home';
import CampaignIcon from '@mui/icons-material/Campaign';
import './Sidebar.scss'
import Drawer from '@mui/material/Drawer';
import { IconContext } from "react-icons";

const ListMenu = () => (
  <List>
          <Link to="/">
          <ListItem button>
                <ListItemIcon>
                <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={'Home'} />

              </ListItem>
          </Link>
          <Link to="/campaigns">
          <ListItem button>
                <ListItemIcon>
                <CampaignIcon />
                </ListItemIcon>
                <ListItemText primary={'Campaigns'} />

              </ListItem>
          </Link>
        </List>
)
const Sidebar = () => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(open);
  };
  return (
    <>
      <nav>
        <ListMenu />
      </nav>
      <div className='mobileMenu'>
        <div onClick={toggleDrawer(true)}>
        <IconContext.Provider value={{ color: "#137E84", size: 30 }}>
            <BiMenuAltRight />
          </IconContext.Provider>
        </div>
        <Drawer
            anchor='left'
            open={open}
            onClose={toggleDrawer(false)}
          >
            <ListMenu />
          </Drawer>
      </div>
    </>
  )
}

export default Sidebar