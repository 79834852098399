import "./App.scss";
import { Sidebar } from "./containers";
import { Home } from "./pages";
const App = () => {
  return (
    <>
      <Sidebar />
      <main>
        <Home />
      </main>
    </>
  );
};

export default App;
