import React from "react";
import "./CampaignCard.scss";
import { createCampaignObject, relativeDate, formateDate } from "../../utils";
import Modal from "@mui/material/Modal";
import Popup from "../Popup/Popup";
import urgentIcon from "../../assets/imgs/urgent.svg";
import { AiFillInfoCircle } from "react-icons/ai";
import { IconContext } from "react-icons";

const CampaignCard = ({ campaign, merchant, focus }) => {
  const campaignItem = createCampaignObject(campaign, merchant);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { day, dayDisplay } = relativeDate(campaign.end_date);

  return (
    <>
      <div
        className={`campaign ${!focus ? "disable" : ""}`}
        onClick={handleOpen}
      >
        <div
          className="campaign-header"
          style={{ backgroundImage: `url(${campaignItem.header_image})` }}
        >
          <div className={`campaign-header__date ${day < 3 ? "warning" : ""}`}>
            {day < 3 ? (
              <div>
                <img src={urgentIcon} alt="urgent" />
                <p>{`${dayDisplay}`}</p>
              </div>
            ) : (
              <p>{`${formateDate(campaign.end_date)}`}</p>
            )}
          </div>
        </div>
        <div className="campaign-content">
          <div className="campaign-content__logo__container">
            <div className="campaign-content__logo">
              <img src={campaignItem.logo} alt={campaignItem.merchant_name} />
            </div>
            {campaignItem.extra_offer_info && (
              <div className="campaign-content__notification">
                <IconContext.Provider
                  value={{ color: "blue", className: "infoIcon", size: 20 }}
                >
                  <AiFillInfoCircle />
                </IconContext.Provider>
              </div>
            )}
          </div>
          <div className="campaign-content__info">
            <h5>{campaignItem.discount_value} Cashback</h5>
            <p>{campaignItem.merchant_name}</p>
          </div>
        </div>
        <div className="campaign-description">
          <p>{campaignItem.subtitle}</p>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Popup campaign={campaignItem} />
      </Modal>
    </>
  );
};

export default CampaignCard;
