export function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export const createCampaignObject = (campaign, merchant) => {
    let cashback;
    let campaignItem;
    if(campaign.cashback_is_fraction) {
        cashback = round(campaign.cashback_fraction * 100, 1) + '%';
    } else {
        cashback = round(campaign.cashback_amount,0) + ' EUR';
    }
    if(campaign && merchant) {
        campaignItem = {
            discount_value: cashback,
            merchant_name: merchant.name,
            title: campaign.title,
            subtitle: campaign.description,
            logo: merchant.logo,
            header_image: campaign.header_image,
            popup_image: campaign.popup_image,
            description: campaign.description,
            details: campaign.details,
            conditions: campaign.conditions,
            url: campaign.url,
            end_date: campaign.end_date,
            extra_offer_info: campaign.extra_offer_info,
            url_text: campaign.url_text
        }
    }
    return campaignItem
}


export const relativeDate = (dateToCheck) => {
    const dateConverted = typeof dateToCheck === 'string' ? new Date(dateToCheck) : dateToCheck
    const timerInSeconds = dateConverted.getTime() / 1000
    const today = new Date().getTime() / 1000;
    let seconds = today - timerInSeconds;
    seconds = Number(seconds);
    const day = Math.abs(Math.floor(seconds / (3600*24)));
    const almostTimeText = `${day < 2 ? 'Falta' : 'Faltam'} ${day} ${day < 2 ? 'dia' : 'dias' }`;
    const endsTodayText = day < 1 ? 'Termina hoje' :'';
    const dayDisplay = day > 0 ? almostTimeText : endsTodayText;
    return {day, dayDisplay};
}

export const formateDate = (dateToCheck) => {
    const dateConverted = typeof dateToCheck === 'string' ? new Date(dateToCheck) : dateToCheck
    return new Intl.DateTimeFormat("pt-PT", {
        month: "long",
        day: "2-digit"
      }).format(dateConverted)
}

export const getSiteFromUrl = (url) => {
    if (url === null || url === undefined)
        return url;
    const siteLink = url.split('/');
    return url.slice(0, 4) == 'http' ? siteLink[2] : siteLink[0];
}