import React, {useState} from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './Search.scss'
const Search = ({searchCampaign}) => {
  const [id, setId] = useState('');
  const handleChange = (event) => {
    setId(event.target.value);
  };
  const handleOnClick = () => {
    searchCampaign(id)
  }
  return (
    <Box
      component="form"

      noValidate
      autoComplete="off"
    >
      <div className='searchBlock'>
      <TextField id="outlined-basic" label="Campaign ID" onChange={handleChange} variant="outlined" />
      <Button variant="contained" onClick={() => handleOnClick()}>GO</Button>
      </div>
    </Box>
  )
}

export default Search