import React from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { IconContext } from "react-icons";
import { createCampaignObject, relativeDate } from "../../utils";
import Modal from '@mui/material/Modal';
import Popup from "../Popup/Popup";
import "./CarouselItem.scss";

const CarouselItem = ({ campaign, merchant }) => {
  const { day, dayDisplay } = relativeDate(campaign.end_date);
  const campaignItem = createCampaignObject(campaign, merchant);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className="carouselItem"  onClick={handleOpen} >
        <div className="carouselItem-warning">
          {day < 3 && <p>{`${dayDisplay}`}</p>}
        </div>
        <div className="carouselItem-logo__container">
          <div className="carouselItem-logo">
            <img src={campaignItem.logo} alt={campaignItem.merchant_name} />
          </div>
          {campaignItem.extra_offer_info && (
            <div className="carouselItem-notification">
              <IconContext.Provider
                value={{ color: "blue", className: "infoIcon", size: 20 }}
              >
                <AiFillInfoCircle />
              </IconContext.Provider>
            </div>
          )}
        </div>
        <div className="carouselItem-subtitle">
          <p>{campaignItem.discount_value}</p>
        </div>
        <div className="carouselItem-merchant">
          <p>{campaignItem.merchant_name}</p>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Popup campaign={campaignItem} />
      </Modal>
    </>
  );
};

export default CarouselItem;
